<template>
    <div class="message-card">
        <h5 :class="header" class="pb-2 border-bottom">&raquo; {{ msg.title }}</h5>
        <message-viewer :msg="msg.message"></message-viewer>

        <div class="small">
            <i class="far fa-clock fa-fw mr-1"></i><i>Modified on {{ formatTime(msg.recordChanged) }}</i>
        </div>
    </div>
</template>

<style>
    .message-card {
        margin-bottom: 4rem;
    }
</style>

<script>
import dayjs from "dayjs";
import MessageViewer from "@/components/Messages/MessageViewer";


export default {
    name: "MessageRecord",
    components: {
        MessageViewer
    },
    props: {
        msg : {
            type: Object
        }
    },
    data() {
        return {
            styleOptions: ['Success', 'Warning', 'Danger']
        }
    },

    computed: {
        isActive() {
            const now = new Date();
            const enable = new Date(this.msg.enable);
            const disable = new Date(this.msg.disable);
            return enable < now && now < disable;
        },
        header() {
            const style = this.msg.style;
            return style && this.styleOptions.includes(style) ? `text-${ style.toLowerCase() }` : '';
        }
    },

    methods: {
        formatTime(time) {
            return dayjs(time).format('ddd, MMM D, YYYY h:mm A')
        },
    }
}
</script>
