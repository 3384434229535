<template>
    <div class="container text-center">
        <div class="notice pt-5">
            <h1>IBM Blue Diamond</h1>
            <hr>
            <h3 class="text-muted">Maintenance In Progress</h3>
            <h3 class="text-muted">We will be online shortly</h3>
        </div>
    </div>
</template>

<style scoped>
    .container {
        background-color: #f7f7f7;

        margin: 0;
        min-height: 100vh;
        min-width: 100vw;

        -webkit-box-shadow: inset 0 14px 15px -12px rgba(0, 0, 0, 1);
        -moz-box-shadow: inset 0 14px 15px -12px rgba(0, 0, 0, 1);
        box-shadow: inset 0 14px 15px -12px rgba(0, 0, 0, 1);
    }

    .notice {
        min-width: 526px!important;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%) translateY(-60%);
    }
</style>

<script>
    import cookies from 'js-cookie'

    export default {
        name: 'maintenance',
        data() {
            return {
                verifyInterval: undefined
            }
        },
        mounted() {
            this.verifyInterval = setInterval(this.checkStatus, 10 * 1000);
            setTimeout(this.checkStatus, 1000);
        },
        beforeDestroy() {
            clearInterval(this.verifyInterval);
        },


        methods : {
            checkStatus() {
                this.$http.get('/api/auth')
                    .then(data => {
                        // Update the token all the time in case it changes
                        const token = cookies.get('XSRF-TOKEN');
                        this.$store.commit('xsrf', token);

                        const loginInfo = data.data;
                        const user = loginInfo.auth;

                        // User is not logged in, redirect user to login page with redirect link
                        if (user === null || user === "anonymousUser") {
                            // Update store if user info doesn't match
                            if (this.$store.user !== undefined) {
                                this.$store.commit('logout');
                            }

                            // Login with direct to current page
                            this.$router.push({
                                name : 'login',
                                query: {
                                    redirect: this.$route.query.redirect,
                                },
                            });
                            return;
                        }

                        // Update login info
                        this.$store.commit('login', data.data.auth);
                        this.$router.push(this.$route.query.redirect || { name : 'home'})
                    });
            }
        }
    }
</script>