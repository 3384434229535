<template>
    <div class="message-viewer" ref="msgBodyViewer"></div>
</template>

<style>
.message-viewer .ql-editor {
    padding: 1rem;
}
</style>

<script>
import Quill from 'quill'

export default {
    name: "MessageViewer",

    props: {
        msg: {
            type: String,
            required: false,
        },
        modifying: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            editor: undefined,
        }
    },

    mounted() {
        const msg = this.msg;
        const editor = new Quill(this.$refs.msgBodyViewer, {
            readOnly: true
        });
        editor.setContents(JSON.parse(msg))
        this.editor = editor;
    },

}
</script>