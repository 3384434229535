<template>
    <b-card class="mb-3" :border-variant="border" no-body>
        <b-card-title :class="header" class="m-0 p-3 border-bottom d-flex align-items-center justify-content-between">
            <span>
                <i class="far fa-trash-alt fa-fw mr-2 text-danger" v-if="msg.deleted" v-b-tooltip.hover.v-dark title="This message has been deleted."></i>
                &raquo; {{ msg.title }}
            </span>
            <i class="fas fa-users " v-b-tooltip.hover.v-dark :title="visTitle"></i>
        </b-card-title>

        <b-card-body class="p-0">
            <message-viewer :msg="msg.message"></message-viewer>

            <div v-if="editMode">
                <hr>
                <message-editor class="p-2" :msg="msg" :modifying="modifying" @update="childUpdate" @modifying="childModifying"></message-editor>
            </div>

        </b-card-body>

        <b-card-footer>
            <div class="d-flex align-items-center justify-content-between">
                <div class="small text-muted font-italic">
                    <div v-b-tooltip.hover.v-dark title="Message enable time"><i class="far fa-eye fa-fw mr-1" ></i>{{ formatTime(msg.enable) }}</div>
                    <div v-b-tooltip.hover.v-dark title="Message disable time"><i class="far fa-eye-slash fa-fw mr-1"></i>{{ formatTime(msg.disable) }}</div>
                    <div v-b-tooltip.hover.v-dark title="Last Modified Time"><i class="fas fa-pen fa-fw mr-1"></i>{{ formatTime(msg.recordChanged) }}</div>
                    <div v-b-tooltip.hover.v-dark :title="visTitle"><i class="fas fa-users fa-fw mr-1"></i>Visibility: {{ msg.visibility }}</div>
                </div>

                <b-button-group v-if="editable">
                    <b-button :disabled="modifying" variant="outline-success" v-if="!msg.active" @click="activeDeactivateRecord(true)" v-b-tooltip.hover.v-dark title="This message is currently disabled and only visible to admins. Enabling this will show the announcement to the intended users."><i class="fas fa-exclamation fa-fw"></i> <span class="d-none d-lg-inline">Activate</span></b-button>
                    <b-button :disabled="modifying" variant="outline-primary" v-if="msg.active" @click="activeDeactivateRecord(false)" v-b-tooltip.hover.v-dark title="This message is currently active. Disabling this message will prevent it from being shown to users (except admins)."><i class="far fa-eye-slash fa-fw"></i> <span class="d-none d-lg-inline">Disable</span></b-button>
                    <b-button :disabled="modifying" variant="outline-primary" @click="editMode = !editMode" v-b-tooltip.hover.v-dark title="Edit Message"><i class="far fa-edit fa-fw"></i> <span class="d-none d-lg-inline">Edit</span></b-button>
                    <b-button :disabled="modifying" variant="outline-danger" @click="deleteMsg" v-b-tooltip.hover.v-danger title="Delete Message"><i class="far fa-trash-alt fa-fw"></i> <span class="d-none d-lg-inline">Delete</span></b-button>
                </b-button-group>
            </div>
        </b-card-footer>
    </b-card>
</template>

<script>
import dayjs from "dayjs"
import { stringify } from "querystring";
import MessageViewer from "@/components/Messages/MessageViewer";
import MessageEditor from "@/components/Messages/MessageEditor";

export default {
    name: 'MessageRecordAdmin',
    components: {
        MessageViewer,
        MessageEditor
    },

    props: {
        msg : {
            type: Object
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
        modifying: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            editMode: false,
        }
    },

    computed: {
        header() {
            const style = this.msg.style;
            const text = style && ['Success', 'Warning', 'Danger'].includes(style) ? `text-${ style.toLowerCase() }` : '';
            const active = this.msg.active ? '' : 'bg-dark';
            const contrast = active && !text && 'text-white' || '';

            return `${active} ${text} ${contrast}`.trim();
        },

        visTitle() {
            const vis = this.msg.visibility;
            const map = {
                'All': 'Visible to Everyone.',
                'External': 'Visible to Customers and ITVendors',
                'Internal': 'Visible to IBMers and Business Partners',
                'Customer': 'Visible to all External Clients',
                'Vendor': 'Visible to all ITVendor Clients',
                'BPV': 'Visible to all Business Partners',
                'IBM': 'Visible to IBMers only',
                'BDADMIN': 'Visible only to BD Admins.',
            };

            return map.hasOwnProperty(vis) ? vis + ": " + map[vis] : vis;
        },


        border() {
            const now = new Date();
            const e = new Date(this.msg.enable);
            const d = new Date(this.msg.disable);

            if (!this.msg.active) { // Deleted
                return 'dark'
            } else if (e < now && now < d) { // Now, active / disabled
                return this.msg.active ? 'success' : 'warning';
            } else if (e > now) { // coming up
                return 'warning'
            }

            return 'danger';
        },
    },

    methods: {
        childUpdate() {
            this.$emit('update');
        },
        childModifying(evt) {
            this.$emit('modifying', { modifying: evt.modifying });
        },

        notice(title, msg, variant) {
            this.$bvToast.toast(msg, {
                title,
                variant,
                toaster: 'b-toaster-top-center',
                solid: true,
            })
        },

        formatTime(time) {
            return dayjs(time).format('ddd MMM D, YYYY h:mm A')
        },

        activeDeactivateRecord(active) {
            this.$emit('modifying', { modifying: true });
            this.$http.put(`/api/messages/${ this.msg.id }/active`, stringify({ active }))
                .then(() => {
                    this.notice("Success", active ? "Successfully activated message." : "Successfully deactivated message.", 'success')

                    setTimeout(() => {
                        this.$emit('update');
                    }, 500);
                }).catch((error) => {
                    const data = error.response && error.response.data || {};
                    const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error modifying message.";
                    this.noticeErr(msg);
                }).finally(() => {
                    this.$emit('modifying', { modifying: false });
                });
        },

        deleteMsg() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this message?', {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'outline-danger',
                cancelVariant: 'outline-dark',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value !== true) {
                    this.notice('Delete', "Message was not deleted.", 'warning')
                    return;
                }

                this.$emit('modifying', { modifying: true });
                this.$http.delete(`/api/messages/${ this.msg.id }`)
                    .then(() => {
                        this.notice('Delete', "Message successfully deleted.", 'success')

                        setTimeout(() => {
                            this.$emit('update');
                        }, 500);
                    }).catch((error) => {
                        const data = error.response && error.response.data || {};
                        const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error deleting message.";
                        this.noticeErr(msg);
                    }).finally(() => {
                        this.$emit('modifying', { modifying: false });
                    });
            }).catch(() => {
                this.notice('Delete', "Failed to delete the message.", 'danger')
            })
        },
    },
}
</script>
