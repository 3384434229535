<template>
    <b-jumbotron :bg-variant="general" text-variant="white" class="text-center py-4 mb-1 custom-box-shadow">
        <h1 class="display-4 font-weight-bold">
            <i :class="icon"></i>
            Blue Diamond Status
        </h1>
    </b-jumbotron>
</template>

<script>
export default {
    name: "GeneralStatus",

    props: {
        general: {
            validator: function (value) {
                return ['success', 'warning', 'danger'].indexOf(value) !== -1
            }
        }
    },

    computed: {
        icon() {
            const map = {
                success: 'fa-check',
                warning: 'fa-exclamation-triangle',
                danger: 'fa-exclamation',
            };

            const icon = map[this.general];
            return `fas fa-fw ${icon}`
        }
    }
}
</script>
