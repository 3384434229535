<template>
    <div class="row record px-1 py-1 justify-content-between">
        <h5 class="record-title">{{ status.name }}</h5>

        <div class="text-center">
            <b-badge class="px-3 py-2 mb-0 record-status" :id="id" :variant="variant">{{ statusText }}</b-badge>
        </div>

        <b-popover :target="id" triggers="hover focus" placement="top">
            <a :href="status.url" target="_blank">{{ status.url }}</a>
        </b-popover>
    </div>
</template>

<style scoped>
.record {
    border-bottom: 1px solid transparent;
}

.record:hover {
    border-bottom-color: #eceeef;
}

.record-title {
    font-weight: 300;
}

.record-status {
    font-size: 14px;
    font-weight: 300;
}
</style>

<script>
export default {
    name: "StatusRecord",
    props: {
        data: 'String',
        status : {
            type: Object
        },
    }, data() {
        return {
            color: {
                Online: 'success',
                Offline: 'danger',
                Maintenance: 'warning',
                Degraded: 'warning'
            }
        }
    },
    computed: {
        id() {
            return `badge-${this.status.code}`
        },
        statusText() {
            return this.status.status;
        },
        variant() {
            return this.color[this.status.status]
        },
    },
}
</script>
