<template>
    <div class="home">
        <div class="container-fluid">
            <div v-if="loading" class="text-center mt-4">
                <h3><b-spinner type="grow" variant="success" label="Loading..." class="mr-2" /> Loading...</h3>
            </div>

            <b-alert variant="danger" v-else-if="hasErr">
                {{ loadingErr }}
            </b-alert>

            <template v-else>
                <div v-if="system && system.general" class="row mt-n3 mb-4">
                    <general-status class="col" :general="system.general"></general-status>
                </div>

                <div class="d-flex justify-content-center mx-auto" style="max-width: 1900px;">
                    <status-pane class="col col-sm-12 col-md-6 col-lg-4" :system="system" ></status-pane>
                    <message-pane class="col-sm-12 col-md-6 col-lg-8 mt-xs-5 mt-sm-5 mt-md-0" v-if="messages.length > 0" :messages="messages" @update="load" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import GeneralStatus from '@/components/System/GeneralStatus'
    import StatusPane from '@/components/System/StatusPane'
    import MessagePane from "@/components/Messages/MessagePane";
    import { isArray } from 'lodash'

    export default {
        name: 'Home',
        components: {
            GeneralStatus,
            MessagePane,
            StatusPane,
        },
        data() {
            return {
                loadingSystem: false,
                loadingMessage: false,
                hasErr: false,
                loadingErr: "There was an error while loading. Please try again.",

                system: undefined,
                messages: undefined,
                interval: undefined,
            }
        },

        computed: {
            loading() {
                return this.loadingSystem || this.loadingMessage;
            },
        },

        created() {
            this.Util.setTitle("Status");
            this.load();
            setInterval(this.load,  5 * 60000)
            setTimeout(() => {
                if (this.interval) {
                    clearInterval(this.interval);
                }
            }, 60 * 60000)
        },
        beforeDestroy() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },

        methods: {
            load() {
                if (this.loading) return;
                this.hasErr = false;
                this.getSystemStatus();
                this.getMessages();
            },

            getSystemStatus() {
                if (this.loadingSystem === true) return;
                this.loadingSystem = true;
                this.$http.get('/api/system').then((data) => {
                    if (data.data) {
                        this.system = data.data;
                    } else {
                        this.hasErr = true;
                    }
                })
                .catch(() => {
                    this.hasErr = true;
                }).then(() => {
                    this.loadingSystem = false;
                });
            },

            getMessages() {
                if (this.loadingMessage === true) return;
                this.loadingMessage = true;

                this.$http.get('/api/msg').then((data) => {
                    if (data.data && isArray(data.data)) {
                        this.messages = data.data;
                    } else {
                        this.hasErr = true;
                    }
                })
                .catch(() => {
                    this.hasErr = true;
                }).then(() => {
                    this.loadingMessage = false;
                });
            }
        }
    }
</script>
