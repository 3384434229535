import cookies from 'js-cookie'
import http from 'axios'
import store from '@/store'

export default (to, from, next) => {
    // Don't check any state if it's a public resource
    if (to.name === 'login' || to.name === 'w3login' || to.meta.public === true)  {
        next();
        return;
    }

    if (store.state.loggedIn) {
        next();
    }

    http.get('/api/auth')
        .then(data => {
            // Update the token all the time in case it changes
            const token = cookies.get('XSRF-TOKEN');
            store.commit('xsrf', token);

            const loginInfo = data.data;
            const user = loginInfo.auth;

            // User is not logged in, redirect user to login page with redirect link
            if (user === null || user === "anonymousUser") {
                // Update store if user info doesn't match
                if (store.user !== undefined) {
                    store.commit('logout');
                }

                // Login with direct to current page
                next({
                    name : 'login',
                    query: {
                        redirect: to.fullPath,
                    },
                });
                return;
            }

            // Update login info
            store.commit('login', data.data.auth);
            next();
        }).catch(err => {
            console.log(err); // need to have a global error logger
            next({
                name : 'maintenance',
                query: {
                    redirect: to.fullPath,
                },
            });
        });
};