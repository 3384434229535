<template>
    <div class="container-fluid login-page">
        <div class="row d-flex justify-content-center">
            <div class="col-11 col-xl-6 login-form p-5 my-5 mx-2">
                <div class="text-center">
                    <img alt="IBM" class="pb-3" height="75px" src="../../assets/ibm_logo_black_large.png">
                    <h2 class="title"><strong>Blue Diamond Status</strong></h2>
                    <hr>
                </div>

                <b-alert class="text-center" :show="notice.timer" dismissible variant="danger" @dismissed="notice.timer=0" @dismiss-count-down="countDownChanged">
                    <h6 class="mb-3" style="font-weight: 500;">{{notice.msg}}</h6>
                    <b-progress variant="danger" :max="notice.duration" :value="notice.timer" height="2px" />
                </b-alert>

                <div class="text-center h2" v-if="token === undefined">
                    <b-spinner type="grow" variant="success" label="Loading..." /> Loading...
                </div>
                <div v-else>
                    <b-form @submit.prevent="login">

                        <b-form-group>
                            <b-input-group id="usernameGroup" >
                                <b-form-input id="username" type="text" v-model="form.username" required placeholder="Blue Diamond User ID" :disabled="loading" autofocus />
                            </b-input-group>
                        </b-form-group>
                        <b-form-group>
                            <b-input-group id="passwordGroup">
                                <b-form-input id="password" type="password" v-model="form.password" required placeholder="Enter Password" :disabled="loading" />
                            </b-input-group>
                        </b-form-group>

                        <b-button block type="submit" variant="primary" :disabled="loading">Login</b-button>

                        <div class="text-center pt-3">
                            To create or manage your Blue Diamond User ID or Password, visit our <a href="https://msciportal.im-ies.ibm.com/">Registration Portal.</a>
                        </div>
                    </b-form>


                    <hr style="border-top-width: 3px;">
                    <div class="mt-5">
                        <h4 class="text-center">
                            <strong>w3ID (Bluepages) Login</strong>
                        </h4>
                        <b-button block id="w3LoginButton" class="mt-3" variant="outline-primary" :disabled="loading"
                                  href="/api/public/login-w3id/redirect">
                            Login with w3ID <i class="fas fa-external-link-alt pl-1"></i>
                        </b-button>
                        <b-tooltip target="w3LoginButton">
                            <strong>Intended for IBMers only.</strong>
                        </b-tooltip>
                    </div>

                    <hr>
                    <footer class="footer text-center">
                        <p>IBM Blue Diamond Lab | <a href="https://www.ibm.com/privacy/">IBM Privacy Policy</a></p>
                    </footer>
                </div>

            </div>
        </div>

        <!-- Id Not Email Modal -->
        <b-modal ref="emailWarningModal" hide-footer header-bg-variant="warning" header-text-variant="light">
            <template v-slot:modal-title>
                <strong style="font-weight: 700;">Invalid Login</strong>
            </template>

            <p>It seems you entered an email address. Blue Diamond User IDs are not email address. You can look up a forgotten Blue Diamond User ID at our <a href="https://msciportal.im-ies.ibm.com/">Registration Portal.</a> Alternatively, <strong>IBMers</strong> can sign in via the w3id form below.</p>
        </b-modal>
    </div>
</template>

<style scoped>
    .title {
        font-weight: 300;
    }

    .login-page {
        width: 100%;
        height: 100vh;
    }

    .login-form {
        background-color: rgba(255, 255, 255, .9);
        border-radius: 4px;
        max-width: 600px;
        -webkit-box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
        -moz-box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
        box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
    }
</style>

<script>
    import qs from 'querystring'
    import cookies from 'js-cookie'
    import { isObject } from 'lodash'

    export default {
        name: 'login',
        data() {
            return {
                form: {
                    username: '',
                    password: '',
                },
                passwordTimeout: undefined,
                token: undefined,
                loading: false,
                notice: {
                    msg: "",
                    duration: 10,
                    timer: 0,
                },
            }
        },
        mounted() {
            this.$store.token = undefined;
            this.$http.get('/api/auth').then((resp) => {
                // check data and redirect if necessary ?
                this.token = cookies.get('XSRF-TOKEN');
                this.$store.commit('xsrf', this.token);

                this.handleAuthResponse(resp, false);
            });

            if (this.$route.query.w3IdError) {
                this.showNotice(true)
            }
        },
        methods: {
            login() {
                if (!this.form.username || !this.form.password) {
                    return;
                }

                if (this.form.username.indexOf("@") > -1) {
                    this.$refs['emailWarningModal'].show();
                    return;
                }

                this.clearNotice();
                this.$http.post('/api/auth', qs.stringify(this.form), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-XSRF-TOKEN' : this.token
                    }
                }).then(resp => {
                    this.handleAuthResponse(resp, true);
                }).catch(() => {
                    this.showNotice();
                });
            }, handleAuthResponse(resp, showErr) {
                let data = resp.data;

                if ((data.error && data.error === true) || (data.auth && data.auth === 'anonymousUser')) {
                    showErr === true && this.showNotice();
                    return;
                }

                if (isObject(data.auth)) {
                    this.$store.commit('login', data.auth);
                    this.$router.push(this.$route.query.redirect || { name: 'home' })
                } else {
                    showErr === true && this.showNotice();
                }
            }, showNotice(isW3IDError) {
                this.notice.msg = isW3IDError === true ?
                    "w3ID login failed. Please try again."
                    : "Login failed, Please try again.";
                this.notice.timer = this.notice.duration;
                this.loading = false;
            }, clearNotice() {
                this.notice.timer = 0;
                this.loading = true;
            }, countDownChanged(timer) {
                this.notice.timer = timer;
            }
        }
    }
</script>