<template>
    <div id="app">
        <app-nav-bar v-if="isLoggedIn"></app-nav-bar>
        <transition name="appPageTransition" mode="out-in" enterActiveClass="animated fadeIn" leaveActiveClass="animated fadeOutLeft faster">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppNavBar from  '@/components/AppNavBar';

export default {
    name: 'app',
    components: { AppNavBar },
    computed: {
        ...mapGetters([
            'isLoggedIn'
        ]),
    },
}
</script>
