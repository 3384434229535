// Vue Libraries
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// External stand-alone libraries
import http from 'axios'
import * as Util from './util'

// External libraries
import BootstrapVue from 'bootstrap-vue'

// Styling
import './assets/app.scss';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

Vue.prototype.Util = Util;
Vue.prototype.$http = http;

http.interceptors.response.use(resp => resp, error => {
    if (error.response.status === 401) {
        router.push({
            name : 'login',
            query: {
                redirect: (window.location.pathname + window.location.search),
            },
        });
    } else {
        return Promise.reject(error);
    }
});


Vue.filter('formatDate', Util.formatDate);
Vue.filter('formatDateMinimal', date => Util.formatDate(date, 'MMM DD, YYYY h:mm A'));
Vue.filter('prefixIfNotEmpty', (str , prefix) => str ? prefix + str : str);


new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app');
