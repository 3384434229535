import {isEmpty, trim} from 'lodash';
import dayjs from "dayjs";



/**
 * Set page title
 * @param str {string} page title
 */
export const setTitle = (str) => {
    if (document && str) {
        str = trim(str);
        const title = !isEmpty(str) ? str : "Status";
        document.title = title + " | IBM Blue Diamond";
    }
};


/**
 * Format Date using Moment
 * @param date date/moment/time reference
 * @param format preferred format, else "ddd, MMM D, YYYY h:mm A"
 * @returns {*} formatted date else 'N/A'
 */
export const formatDate = (date, format = 'ddd, MMM D, YYYY h:mm A') => {
    // return date && moment(date).format(format) || 'N/A';
    return date && dayjs(date).format(format) || 'N/A';
};