import Vue from 'vue'
import VueRouter from 'vue-router'
import authmgr from '@/util/router-auth-mgr'

import Home from '@/views/Home'
import W3Login from '@/views/meta/W3Login'
import Login from '@/views/meta/Login'
import Logout from '@/views/meta/Logout'
import NotFound from '@/views/meta/NotFound'
import Maintenance from '@/views/meta/Maintenance'

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Main Pages
    ////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/admin/system',
        name: 'system',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/SystemManager'),
    },
    {
        path: '/admin/system/override-history',
        name: 'override-history',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/OverrideHistory'),
    },
    {
        path: '/admin/system/history',
        name: 'system-history',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/SystemStatusHistory'),
    },
    {
        path: '/admin/messages',
        name: 'messages',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/MessageAdmin'),
    },
    {
        path: '/admin/messages/history',
        name: 'message-history',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/MessageHistory'),
    },
    {
        path: '/admin/banners',
        name: 'banners',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/BannerAdmin'),
    },



    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Meta Pages
    ////////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/login/w3id/callback',
        name: 'w3login',
        component: W3Login
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            public: true
        }
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
        meta: {
            public: true
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(authmgr);

export default router
