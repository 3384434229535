<template>
    <div class="container text-center">
        <div class="pt-5">
            <h3><b-spinner type="grow" variant="success" label="Loading..." class="mr-2" /> Logging Out...</h3>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
    export default {
        name: 'logout',
        mounted() {
            this.logout();
        },
        methods: {
            logout() {
                this.$http.post('/api/logout').then(() => {
                    this.$store.commit('logout');
                    this.$router.push({'name' : 'login'});
                });
            }
        }
    }
</script>