<template>
    <div id="status">
        <h2 class="text-primary text-center  pb-2 border-bottom mb-4">System Status</h2>

        <div class="px-3">
            <div class="row border-bottom mb-3">
                <h4 class="text-primary">Internet Systems</h4>
            </div>
            <status-record v-for="rec in system.global" :key="rec.code" :status="rec"></status-record>

            <div v-if="showInternal" class="mt-5">
                <div class="row border-bottom mb-3">
                    <h4 class="text-primary">Intranet Systems (Blue)</h4>
                </div>
                <status-record v-for="rec in system.intranet" :key="rec.code" :status="rec"></status-record>
            </div>
        </div>
    </div>
</template>

<script>
import StatusRecord from "@/components/System/StatusRecord";

export default {
    name: "StatusPane",
    components: {
        StatusRecord
    },
    props: {
        system : {
            type: Object
        }
    },
    computed: {
        showInternal() {
            return this.system.intranet && this.system.intranet.length > 0
        }
    },
}
</script>
