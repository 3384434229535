<template>
    <div class="container-fluid login-page">
        <div class="row justify-content-md-center">
            <div class="col-xl-4 col-md-6 login-form p-5 my-5 mx-2">
                <div class="text-center">
                    <img alt="IBM" class="pb-3" height="75px" src="../../assets/ibm_logo_black_large.png">
                    <h2><strong>Blue Diamond Status</strong></h2>
                    <hr>
                </div>

                <div class="text-center h2 mb-3">
                    <b-spinner type="grow" variant="success" label="Loading..." /> Loading...
                </div>

                <footer class="footer text-center">
                    <p>IBM Blue Diamond Lab | <a href="https://www.ibm.com/privacy/">IBM Privacy Policy</a></p>
                </footer>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .login-page {
        width: 100%;
        height: 100vh;
    }

    .login-form {
        background-color: rgba(255, 255, 255, .9);
        border-radius: 4px;
        -webkit-box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
        -moz-box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
        box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
    }

    .blink {
        animation: blink 1s step-start 0s infinite;
        -webkit-animation: blink 1s step-start 0s infinite;
    }
    @keyframes blink {
        50% {
            opacity: 0.0;
        }
    }
    @-webkit-keyframes blink {
        50% {
            opacity: 0.0;
        }
    }
</style>

<script>
    import qs from 'querystring'
    import cookies from 'js-cookie'
    import {isObject} from 'lodash'


    export default {
        name: 'w3login',
        data() {
            return {
                token: undefined
            }
        },
        computed: {

        },
        mounted() {
            let w3Token = this.$route.query.code;
            if (!w3Token) {
                this.goToLogin();
            }

            if (!this.$store.token) {
                this.$http.get('/api/auth').then((resp) => {
                    // check if already logged in. Might be redundant
                    this.token = cookies.get('XSRF-TOKEN');
                    this.$store.commit('xsrf', this.token);

                    // User is already logged in
                    if (this.handleAuthResponse(resp, false)) {
                        return;
                    }

                    this.handleLogin();
                });
            }
        },
        methods: {
            goToLogin(showError) {
                let query = {};
                if (showError === true) {
                    query.w3IdError = true;
                }

                this.$router.push({ name : 'login' , query });
            },

            handleLogin() {
                let w3Token = this.$route.query.code;
                if (!w3Token) {
                    console.error("Failed to get token from API");
                    return;
                }

                let url = "/api/public/login-w3id/callback?code=" + w3Token;
                this.$http.get(url)
                    .then(resp => {
                        this.handleAuthResponse(resp, true);
                    }).catch(err => {
                        console.log(err);
                        this.goToLogin(true);
                    });
            },

            handleAuthResponse(resp, showErr) {
                let data = resp.data;

                if (data.error && data.error === true) {
                    showErr === true && this.showNotice();
                    return false;
                }

                if (isObject(data.auth)) {
                    console.log("Redirecting to Login")
                    this.$store.commit('login', data.auth);
                    this.$router.push(this.$route.query.redirect || { name : 'home'})
                    return true;
                }

                return false;
            }
        }
    }
</script>