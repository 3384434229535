<template>
    <b-form class="msgEditor" @submit.prevent="saveMessage">
        <h3>{{ formSubmitText }}</h3>
        <b-form-group v-if="createMode" label="Active:" label-for="msgEditorActive" description="Disabling a message will prevent the message from being shown to users. Admins will see the message on the home screen if it's during the 'active' hours.">
            <b-button-group size="sm">
                <b-btn class="msgEditorActive" :variant="activeButtonStyle" @click="active=true" :disabled="modifying">
                    <i class="far fa-check-square" v-if="active"></i>
                    <i class="far fa-square" v-else></i>
                    Active
                </b-btn>
                <b-btn class="msgEditorActiveDisable" :variant="activeDisableButtonStyle" @click="active=false" :disabled="modifying">
                    <i class="far fa-check-square" v-if="!active"></i>
                    <i class="far fa-square" v-else></i>
                    Disable
                </b-btn>
            </b-button-group>
        </b-form-group>
        <hr v-if="createMode" class="col-6">

        <b-form-group class="" label="Message Title:" label-for="msgEditorTitle" description="Enter a title for the message. Limit 250 characters.">
            <b-form-input class="msgEditorTitle" v-model.trim="title" placeholder="Enter Message Title" maxlength="250" :disabled="modifying"></b-form-input>
        </b-form-group>
        <hr class="col-6">

        <b-form-group label="Message:" label-for="msgEditorBody" description="Enter message body.">
            <div class="msgEditorBody" ref="msgBody"></div>
        </b-form-group>
        <hr class="col-6">

        <b-form-group label="Set Message Timeline:" label-for="msgEditorDate" description="Select date and time of when to enable and disable the message.">
            <b-input-group class="msgEditorDate">
                <b-form-datepicker v-model="enableDate" size="sm" button-variant="primary" nav-button-variant="primary" :disabled="modifying"></b-form-datepicker>
                <b-form-timepicker v-model="enableTime" size="sm" minutes-step="15" :disabled="modifying"></b-form-timepicker>
                <b-btn variant="dark" disabled><i class="fas fa-arrow-right fa-fw"></i></b-btn>
                <b-form-datepicker size="sm" v-model="disableDate" nav-button-variant="primary" :min="enableDate" :disabled="modifying"></b-form-datepicker>
                <b-form-timepicker v-model="disableTime" size="sm" minutes-step="15" :disabled="modifying"></b-form-timepicker>
            </b-input-group>
        </b-form-group>
        <hr class="col-6">

        <b-form-group label="Restrict Visibility:" label-for="msgEditorVisibility" description="Choose an option to limit visibility of message: External (Customer/Vendor), Internal (IBM/BPV), etc. ">
            <b-button-group class="msgEditorVisibility" block size="sm">
                <b-btn v-for="vis in userOptions" :key="vis" :variant="computeUserOptionsStyle(vis)" @click.prevent="visibility = vis" :disabled="modifying" v-b-tooltip.hover.v-dark :title="computeVisibilityTooltip(vis)">
                    <i class="far fa-check-square mr-2" v-if="visibility === vis"></i><i class="far fa-square mr-2" v-else></i>{{ vis }}
                </b-btn>
            </b-button-group>
        </b-form-group>
        <hr class="col-6">

        <b-form-group label="Header Style:" label-for="msgEditorStyle" description="(Optional) Choose a style of the message: Default, Success (green), Warning (Yellow), Danger (Red).">
            <b-button-group class="msgEditorStyle" block size="sm">
                <b-btn v-for="opt in styleOptions" :key="opt" :variant="computeStyleButton(opt)" @click.prevent="style = opt" :disabled="modifying">
                    <i class="far fa-check-square mr-2" v-if="style === opt"></i><i class="far fa-square mr-2" v-else></i>{{ opt }}
                </b-btn>
            </b-button-group>
        </b-form-group>
        <hr class="col-6">

        <b-btn type="submit" class="my-3" block variant="outline-primary" :disabled="modifying">
            <i class="fas fa-plus mr-1" v-if="createMode"></i><i class="far fa-save mr-1" v-else></i>{{ formSubmitText }}
        </b-btn>
    </b-form>
</template>

<style lang="scss">
    .msgEditor .form-group > label {
        font-weight: bold;
        padding-left: 3px;
        margin-left: 3px;
    }

    .msgEditor .form-group > label::before {
        content: "\00BB";
        padding-right: 3px;
    }

    .msgEditor small.form-text {
        outline: none;
    }

    .msgEditor hr.col-6 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .msgEditor .ql-editor {
        outline: none;
        min-height: 200px!important;
    }
</style>

<script>
import dayjs from 'dayjs'
import {toLower} from 'lodash'
import qs from 'querystring'
import Quill from 'quill'

export default {
    name: "MessageEditor",
    props: {
        msg : {
            type: Object,
            required: false,
        },
        modifying: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            active: true,
            title: "",
            enableDate: dayjs().format('YYYY-MM-DD'),
            enableTime: dayjs().startOf('hour').format('HH:mm:ss'),
            disableDate: dayjs().add(7, 'day').format('YYYY-MM-DD'),
            disableTime: dayjs().startOf('hour').format('HH:mm:ss'),
            style: 'Default',
            visibility: 'All',

            styleOptions: ['Default', 'Success', 'Warning', 'Danger'],
            userOptions: [ 'All', 'External', 'Internal', 'Customer', 'Vendor', 'BPV', 'IBM', 'BDADMIN'],

            editor: undefined,
        }
    },


    computed: {
        createMode() {
            return this.msg === undefined;
        },
        updateMode() {
            return this.msg !== undefined;
        },
        formSubmitText() {
            return this.createMode ? "Create Message" : "Update Message";
        },

        activeButtonStyle() {
            return this.active ? 'primary' : 'outline-primary'
        },
        activeDisableButtonStyle() {
            return !this.active ? 'danger' : 'outline-danger'
        },

    },

    mounted() {
        this.editor = new Quill(this.$refs.msgBody, {
            theme: 'snow',
            modules: {
                toolbar: [
                    ['clean'],
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block', {'script': 'sub'}, {'script': 'super'}],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'indent': '-1'}, {'indent': '+1'}],
                    [{'header': [4, 5, 6, false]}],
                    [{'color': []}, {'background': []}],
                    [{'align': []}],
                    ['link', 'image']
                ]
            },
            placeholder: 'Insert message body here ...',
            readOnly: false
        });

        if (this.updateMode) {
            const msg = this.msg;
            this.active = msg.active;
            this.title = msg.title;
            this.enableDate  = dayjs(msg.enable).format('YYYY-MM-DD');
            this.enableTime  = dayjs(msg.enable).format('HH:mm:ss');
            this.disableDate = dayjs(msg.disable).format('YYYY-MM-DD');
            this.disableTime = dayjs(msg.disable).format('HH:mm:ss');
            this.style = msg.style;
            this.visibility = msg.visibility;

            this.editor.setContents(JSON.parse(msg.message));
        }
    },
    watch: {
        modifying() {
            this.editor.enable(!this.modifying);
        },
    },

    methods: {
        computeUserOptionsStyle(vis) {
            return vis === this.visibility ? 'primary' : 'outline-primary';
        },

        computeStyleButton(style) {
            const opts = style === 'Default' ? 'primary' : toLower(style);
            return style === this.style ? opts : 'outline-' + opts;
        },

        computeVisibilityTooltip(vis) {
            const map = {
                'All': 'Unrestricted, Visible to Everyone.',
                'External': 'Visible to Customers and ITVendors',
                'Internal': 'Visible to IBMers and Business Partners',
                'Customer': 'Visible to all External Clients',
                'Vendor': 'Visible to all ITVendor Clients',
                'BPV': 'Visible to all Business Partners',
                'IBM': 'Visible to all IBMers',
                'BDADMIN': 'Visible to all BD Admins - Must be set as admin in this tool.',
            };

            return map.hasOwnProperty(vis) ? map[vis] : vis;
        },

        saveMessage() {
            const notice = (title, msg, variant) => {
                this.$bvToast.toast(msg, {
                    title,
                    variant,
                    toaster: 'b-toaster-top-center',
                    solid: true,
                })
            }

            function error(msg) {
                notice('Validation Error', msg, 'danger')
            }

            ///////////////////////////////////////
            // Validate title
            if (this.title === "") {
                error('Title empty, please enter a title.')
                return;
            }
            if (this.title.length > 250) {
                const length_exceeded = this.title.length - 250;
                error(`Title length exceeded by ${length_exceeded} characters, trim the title.`)
                return;
            }

            // Check body?
            const html = this.$refs.msgBody.children[0].innerHTML;
            const message = JSON.stringify(this.editor.getContents())
            if (html === '<p><br></p>') {
                error("Message Body Empty")
                return;
            }

            // Validate Date
            const enableDay = dayjs(this.enableDate + " " + this.enableTime, 'YYYY-MM-DD HH:mm:ss');
            const disableDay = dayjs(this.disableDate + " " + this.disableTime, 'YYYY-MM-DD HH:mm:ss');
            if (enableDay.isAfter(disableDay)) {
                error(`Start time is after disable time. Start time: ${ enableDay.format('ddd MMM DD, YY hh:mm A') }, disable time: ${ disableDay.format('ddd MMM DD, YY hh:mm A') }.`)
                return;
            }

            // Style Options
            if (!this.styleOptions.includes(this.style)) {
                error("Invalid style option selected.")
                return;
            }

            // UserType
            if (!this.userOptions.includes(this.visibility)) {
                error("Invalid user option selected.")
                return;
            }

            // Save
            if (this.createMode) {
                this.$emit('modifying', { modifying: true });

                this.$http.post('/api/messages', qs.stringify({
                    active: this.active,
                    title: this.title,
                    message,
                    html,
                    style: this.style,
                    visibility: this.visibility,
                    enable: enableDay.toJSON(),
                    disable: disableDay.toJSON(),
                })).then(() => {
                    notice("Message Editor", "Message was saved successfully.", "success")

                    setTimeout(() => {
                        this.$emit('update');
                    }, 500);
                })
                .catch((error) => {
                    const data = error.response && error.response.data || {};
                    const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error saving message.";
                    notice("Error Saving Message", msg, 'danger');
                }).finally(() => {
                    this.$emit('modifying', { modifying: false });
                });
            }
            else {
                // Update Mode
                this.$emit('modifying', { modifying: true });

                this.$http.put(`/api/messages/${ this.msg.id }`, qs.stringify({
                    title: this.title,
                    message,
                    html,
                    style: this.style,
                    visibility: this.visibility,
                    enable: enableDay.toJSON(),
                    disable: disableDay.toJSON(),
                })).then(() => {
                    notice("Message Editor", "Message was saved successfully.", "success")

                    setTimeout(() => {
                        this.$emit('update');
                    }, 500);
                })
                .catch((error) => {
                    const data = error.response && error.response.data || {};
                    const msg = data.hasOwnProperty('msg') ? data.msg : "There was an error saving message.";
                    notice("Error Saving Message", msg, 'danger');
                }).finally(() => {
                    this.$emit('modifying', { modifying: false });
                });
            }
            // End Save

        }
    }
}
</script>
