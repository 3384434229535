import Vue from 'vue'
import Vuex from 'vuex'
import http from 'axios'
import { isEmpty } from 'lodash'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        XSRF_TOKEN : null,
        user: undefined,
        loggedIn: false,
    },
    getters: {
        isLoggedIn: state => state.loggedIn === true,
        hasAuth: state => auth => (state.user && state.user.authoritiesList && state.user.authoritiesList.indexOf(auth) > -1) || false,
        isAdmin: state => state.user && state.user.anAdmin || false,
        bdAdm: (state, getters) => getters.hasAuth('ROLE_BLUE_DIAMOND'),
        sysMgr: (state, getters) => getters.hasAuth('ROLE_SYSTEM_MANAGER'),
        msgAdm: (state, getters) => getters.hasAuth('ROLE_MSG_ADMIN'),
    },
    mutations: {
        // Update XSRF Token Library
        xsrf(state, token) {
            const header = 'X-XSRF-TOKEN';
            state.XSRF_TOKEN = isEmpty(token) ? undefined : token;

            // an alternative is to set up axios interceptors (should investigate)
            const httpHeaders = http.defaults.headers.common;
            if (isEmpty(token) && httpHeaders && httpHeaders.includes(header)) {
                delete httpHeaders[header];
            } else {
                httpHeaders[header] = token;
            }
        },

        // Auth: Login / Logout
        login(state, user) {
            if (user) {
                state.user = user;
                state.loggedIn = true;
            } else {
                state.loggedIn = false;
            }
        },
        logout(state) {
            state.user = undefined;
            state.loggedIn = false;
        },
    },
    actions: {

    }
});
