<template>
    <div>
        <h2 class="text-primary text-center pb-2 border-bottom mb-4">Announcements</h2>
        <div v-if="admin" class="text-right mb-2">
            <b-button-group>
                <b-btn :disabled="loading" variant="outline-success" @click="update" v-b-tooltip.hover.v-success title="Refresh"><i class="fas fa-sync-alt fa-fw"></i></b-btn>
                <b-dropdown variant="outline-success" :disabled="loading" right>
                    <template v-slot:button-content>
                        <i class="fas fa-cog fa-fw mr-1"></i>Config
                    </template>

                    <b-dropdown-form @submit.stop.prevent>
                        <b-form-checkbox v-model="showAdminOption" switch class="text-nowrap">Admin View</b-form-checkbox>
                    </b-dropdown-form>
                </b-dropdown>
            </b-button-group>
        </div>

        <message-record-admin v-if="showAdminView" v-for="msg in messages" :key="msg.id" :msg="msg" :editable="true" :modifying="loading" @update="update" @modifying="childModifying"></message-record-admin>
        <message-record v-if="!showAdminView" v-for="msg in messages" :key="msg.id" :msg="msg"></message-record>
    </div>
</template>

<script>
import MessageRecord from "@/components/Messages/MessageRecord";
import MessageRecordAdmin from "@/components/Messages/MessageRecordAdmin";
import { mapGetters } from "vuex";

export default {
    name: "MessagePane",
    components: {
        MessageRecord,
        MessageRecordAdmin
    },
    props: {
        messages : {
            type: Array
        }
    },
    computed: {
        ...mapGetters(['msgAdm', 'isAdmin',]),
        admin() {
            return this.msgAdm || this.isAdmin;
        },
        showAdminView() {
            return this.admin && this.showAdminOption;
        }
    },
    data() {
        return {
            showAdminOption: true,
            loading: false,
        }
    },

    methods: {
        update() {
            this.$emit('update');
        },
        childModifying(evt) {
            this.loading = evt && evt.modifying || false;
        }
    }
}
</script>
